import type { ReactNode } from 'react';
import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import type { Action, Dispatch } from 'redux';
import { bindActionCreators } from 'redux';

import { login } from '../redux/actions/AuthActions';
import { setCurrentBrand } from '../redux/actions/BrandsActions';
import { getConfiguration } from '../redux/actions/ConfigActions';
import BrandChooser from '../components/menu/BrandChooser';
import { RemoteBrand } from '../types/Brand';

type LoginViewProps = {
  login?: Function;
  setCurrentBrand?: Function;
  getConfiguration?: Function;
  history?: Object;
  brands?: RemoteBrand[];
};

type LoginViewState = {
  user: string;
  password: string;
  isFailed: boolean;
};

class LoginView extends Component<LoginViewProps, LoginViewState> {
  state = {
    user: '',
    password: '',
    isFailed: false,
  };

  onBrandSelected(brand: string) {
    const { setCurrentBrand, getConfiguration } = this.props;
    setCurrentBrand && setCurrentBrand(brand);
    getConfiguration && getConfiguration(brand);
  }

  render(): ReactNode {
    const { brands } = this.props;

    return (
      <div className='c-hero-teaser  c-hero-teaser--auto'>
        <Helmet>
          <title>{'Pakiet przeglądów serwisowych VWFS'}</title>
        </Helmet>
        <img
          className='c-hero-teaser__image'
          srcSet='/assets/login/vw-top-1.jpg 480w, /assets/login/vw-top-1.jpg 720w, /assets/login/vw-top-1.jpg 960w, /assets/login/vw-top-1.jpg 1280w, /assets/login/vw-top-1.jpg 1920w'
          src='/assets/login/vw-top-1.jpg'
          sizes='100vw'
          alt='alternative text'
          style={{ height: '100%' }}
        />
        <div id='js-hero-teaser-content' className='c-hero-teaser__content'>
          <div className='c-hero-teaser__content-inner'>
            <div
              className='c-hero-teaser__box'
              style={{ width: '100%', overflow: 'auto' }}>
              <BrandChooser
                brands={(brands || []).map((item) => ({
                  key: item.name,
                  name: item.displayName,
                }))}
                onChoose={(value) => this.onBrandSelected(value)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Object): Object => {
  return {
    brands: state.brands.all.config,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>): Object => {
  const actions = {
    login,
    setCurrentBrand,
    getConfiguration,
  };
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
