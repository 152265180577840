import Api from './Api';

class AuthApi extends Api {
  static resource: string = 'login';

  static login(): any {
    return super
      .post(`${AuthApi.resource}`, {})
      .then((response: Response): Object => response.json())
      .then((data: Object): any => {
        return data;
      });
  }

  static reAuth(): any {
    return super.get('user').then((data: Object): any => {
      return data;
    });
  }
}

export default AuthApi;
