import { AuthAction } from '../constants/AuthAction';
import AuthApi from '../../api/AuthApi';

import LocalStorage, { LocalStorageKeys } from '../../utils/LocalStorage';
import { clearBrands, getBrands } from './BrandsActions';
import { clearOffer } from './OfferActions';
import { clearConfiguration, getConfiguration } from './ConfigActions';
import { setCurrentBrand } from './BrandsActions';

type LoginActionType = {
  type: typeof AuthAction.LOGIN;
};

type LoginFailedActionType = {
  type: typeof AuthAction.LOGIN_FAILED;
};

type LoginSuccessActionType = {
  type: typeof AuthAction.LOGIN_SUCCESS;
  data: Object;
};

type LogoutSuccessActionType = {
  type: typeof AuthAction.LOGOUT_SUCCESS;
};

type ReAuthFailedActionType = {
  type: typeof AuthAction.RE_AUTH_FAILED;
};

type ReAuthSuccessActionType = {
  type: typeof AuthAction.RE_AUTH_SUCCESS;
  data: Object;
};

export type AuthActionType =
  | LoginActionType
  | LoginFailedActionType
  | LoginSuccessActionType
  | LogoutSuccessActionType
  | ReAuthFailedActionType
  | ReAuthSuccessActionType;

const login = (user: string, password: string): Function => {
  return (dispatch: Function) => {
    dispatch(startLogin());
    AuthApi.login(user, password)
      .then((data: Object) => {
        LocalStorage.set(LocalStorageKeys.TOKEN, data.token);
        dispatch(loginSuccess(data));
        dispatch(setCurrentBrand(data.name));
        dispatch(getConfiguration());
        window.dataLayer.push({
          event: 'GAEvent',
          eventCategory: 'Logowanie',
          eventAction: 'Click',
          eventLabel: '',
          loggedBrand: data.name,
          eventNonInteraction: true,
        });
      })
      .catch(() => {
        dispatch(loginFailed());
      });
  };
};

const startLogin = (): AuthActionType => ({
  type: AuthAction.LOGIN,
});

const loginFailed = (): AuthActionType => ({
  type: AuthAction.LOGIN_FAILED,
});

const loginSuccess = (data: any): AuthActionType => ({
  type: AuthAction.LOGIN_SUCCESS,
  data,
});

const logout = (): Function => {
  return (dispatch: Function) => {
    LocalStorage.set(LocalStorageKeys.TOKEN, undefined);
    dispatch(logoutSuccess());
    dispatch(clearBrands());
    dispatch(clearOffer());
    dispatch(clearConfiguration());
    dispatch(getBrands());
  };
};

const logoutSuccess = (): AuthActionType => ({
  type: AuthAction.LOGOUT_SUCCESS,
});

const reAuth = (): Function => {
  return (dispatch: Function) => {
    dispatch(startLogin());
    AuthApi.reAuth()
      .then((data: Object) => {
        dispatch(reAuthSuccess(data));
        dispatch(setCurrentBrand(data.name));
        dispatch(getConfiguration(''));
      })
      .catch(() => {
        dispatch(reAuthFailed());
      });
  };
};

const reAuthFailed = (): AuthActionType => ({
  type: AuthAction.RE_AUTH_FAILED,
});

const reAuthSuccess = (data: any): AuthActionType => ({
  type: AuthAction.RE_AUTH_SUCCESS,
  data,
});

export { login, logout, reAuth, reAuthSuccess };
