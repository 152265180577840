import React from 'react';
import classNames from 'classnames';
import { Brand } from '../../types/Brand';
import { css, StyleSheet } from 'aphrodite';

import { getLogo } from '../../utils/getLogo';

interface BrandChooserProps {
  brands: Brand[];
  onChoose: Function;
}

const BrandChooser = ({ brands, onChoose }: BrandChooserProps) => {
  return (
    <div className={css(styles.contentContainer)}>
      {brands.map((brand: Brand) => (
        <div key={brand.key}>
          <div
            className={classNames({
              [css(styles.brand)]: true,
              [css(styles.brandBig)]: brand.key === 'vwd',
            })}
            onClick={(): void => onChoose(brand.key)}>
            <img
              className={classNames({
                [css(styles.logo)]: true,
                [css(styles.logoBig)]: brand.key === 'vwd',
              })}
              src={getLogo(brand.key)}
              alt=''
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 80,
  },
  brand: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px 40px',
    cursor: 'pointer',
  },
  brandBig: {
    padding: '15px 40px',
  },
  logo: {
    maxHeight: 70,
    maxWidth: 100,
  },
  logoBig: {
    maxHeight: 100,
  }
});

export default BrandChooser;
