const BrandsAction = {
  CLEAR: 'BRANDS_CLEAR',
  GET: 'BRANDS_GET',
  GET_SUCCESS: 'BRANDS_GET_SUCCESS',
  GET_FAILED: 'BRANDS_GET_FAILED',
  SET: 'BRANDS_SET',
  SET_CURRENT: 'BRANDS_SET_CURRENT',
}

export { BrandsAction }
