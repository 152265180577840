import type { ReactNode } from 'react';
import React, { memo } from 'react';

import { ModalLarge, TextHeader } from '../common';
import { Brand } from '../../types/Brand';
import BrandChooser from '../menu/BrandChooser';

type BrandChooserModalProps = {
  brands: Brand[];
  onChoose: Function;
  onClose: Function;
};

const BrandChooserModal = ({
  onClose,
  ...rest
}: BrandChooserModalProps): ReactNode => {
  return (
    <ModalLarge onClose={onClose}>
      <TextHeader type={'h2'}>{'Wybierz markę:'}</TextHeader>
      <BrandChooser {...rest} />
    </ModalLarge>
  );
};

export default memo(BrandChooserModal);
