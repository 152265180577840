import { ConfigAction } from '../constants/ConfigAction'
import ConfigApi from '../../api/ConfigApi'

import { setBrands } from './BrandsActions'
import { setCarProtect } from './CarProtectActions'
import { reAuthSuccess } from './AuthActions'

type ClearConfigurationActionType = {
  type: typeof ConfigAction.CLEAR,
}

type GetConfigurationBeginActionType = {
  type: typeof ConfigAction.GET,
}

type GetConfigurationFailedActionType = {
  type: typeof ConfigAction.GET_FAILED,
}

type GetConfigurationSuccessActionType = {
  type: typeof ConfigAction.GET_SUCCESS,
  data: Object,
}

export type ConfigActionType =
  | ClearConfigurationActionType
  | GetConfigurationBeginActionType
  | GetConfigurationFailedActionType
  | GetConfigurationSuccessActionType

const getConfiguration = (brand): Function => {
  return (dispatch: Function) => {
    dispatch(getConfigurationBegin())
    ConfigApi.get(brand)
      .then((data: Object) => {
        const { brands, carProtectProducts, ...rest } = data.config;
        dispatch(setBrands(brands))
        dispatch(setCarProtect(carProtectProducts));
        dispatch(getConfigurationSuccess(rest))
        dispatch(reAuthSuccess({ displayName: brands[brand].info.displayName }));
      })
      .catch(() => {
        dispatch(getConfigurationFailed())
      })
  }
}

const clearConfiguration = (): ConfigActionType => ({
  type: ConfigAction.CLEAR,
})

const getConfigurationBegin = (): ConfigActionType => ({
  type: ConfigAction.GET,
})

const getConfigurationFailed = (): ConfigActionType => ({
  type: ConfigAction.GET_FAILED,
})

const getConfigurationSuccess = (data: Object): ConfigActionType => ({
  type: ConfigAction.GET_SUCCESS,
  data,
})

export { clearConfiguration, getConfiguration }
