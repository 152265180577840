import Api from './Api';

class BrandsApi extends Api {
  static resource: string = 'brands';

  static get(): any {
    return super
      .get(`${BrandsApi.resource}`)
      .then((data: Object): any => {
        return data;
      });
  }
}

export default BrandsApi;
