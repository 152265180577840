import AudiPNGIcon from '../icons/audi.png'
import AudiIcon from '../icons/audi.svg'
import CupraPNGIcon from '../icons/cupra.png'
import CupraIcon from '../icons/cupra.svg'
import SeatPNGIcon from '../icons/seat.png'
import SeatIcon from '../icons/seat.svg'
import SkodaIcon from '../icons/skoda.png'
import VWPNGIcon from '../icons/vw.png'
import VWIcon from '../icons/vw.svg'
import VWDPNGIcon from '../icons/vw_delivery.png'
import VWDIcon from '../icons/vw_delivery.svg'

const getLogo = (brand: string, isPNG: boolean = false): string => {
  switch (brand) {
    case 'audi':
      return isPNG ? AudiPNGIcon : AudiIcon
    case 'skoda':
      return SkodaIcon
    case 'seat':
      return isPNG ? SeatPNGIcon : SeatIcon
    case 'vw':
      return isPNG ? VWPNGIcon : VWIcon
    case 'vwd':
    case 'vwDelivery':
      return isPNG ? VWDPNGIcon : VWDIcon
    case 'cupra':
      return isPNG ? CupraPNGIcon : CupraIcon
    default:
      return null
  }
}

export { getLogo }
