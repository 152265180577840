import { rgb, PageSizes } from 'pdf-lib';
import {
  addImage,
  createPDF,
  downloadAsPDF,
  drawHeaderText,
  drawTextWithBackground,
  drawLine,
  drawText,
  drawWrappedText,
  loadFont,
} from './pdfMaker';

import { formatPrice } from '../formatters/formatPrice';

const ROW_HEIGHT: number = 28;
const MARGIN: number = 60;
const CELL_MARGIN: number = 6;
const CELL_SHIFT: number = 12;
const SCOPE_WIDTH: number = 60;
const SCOPE_WIDTH_SHORT: number = 20;

const drawScopesHeaders = (
  page: Object,
  headers: Array<string>,
  posY: number
) => {
  const headerOptions = { size: 6 };
  headers.forEach((item: string, idx: number) => {
    if (idx === 0) {
      console.log({ posY });
      drawText(page, item, MARGIN + CELL_MARGIN, posY, headerOptions);
    } else {
      const scopeWidth = item.length < 4 ? SCOPE_WIDTH_SHORT : SCOPE_WIDTH;
      drawWrappedText(
        page,
        item,
        page.getWidth() -
          MARGIN +
          CELL_MARGIN -
          (headers.length - idx) * (scopeWidth + CELL_MARGIN),
        posY,
        scopeWidth,
        'up',
        headerOptions
      );
    }
  });
  drawLine(
    page,
    { x: MARGIN, y: posY + 5 },
    { x: page.getWidth() - MARGIN, y: posY + 5 },
    { thickness: 0.5, color: rgb(0.85, 0.85, 0.85) }
  );
};
const drawTableHeader = (
  page: Object,
  item: Object,
  posY: number,
  type: string
) => {
  const headerOptions = { size: 7 };
  const rowWidth = page.getWidth() - 2 * MARGIN;
  let gridWidth = rowWidth / 12;
  let cellCount = 4;
  if (
    item.installment &&
    item.installment !== '__INSTALLMENT__' &&
    item.symbol
  ) {
    cellCount = 6;
  } else if (
    (item.installment && item.installment !== '__INSTALLMENT__') ||
    item.symbol
  ) {
    cellCount = 5;
  }
  let cellCounter = 0;
  if (item.symbol) {
    drawLine(
      page,
      { x: MARGIN + cellCounter * gridWidth * 0.5, y: posY - 7 },
      { x: MARGIN + cellCounter * gridWidth * 0.5, y: posY + ROW_HEIGHT - 16 },
      { thickness: 0.5, color: rgb(0.3, 0.33, 0.34) }
    );
    drawText(
      page,
      'PAKIET',
      MARGIN + CELL_MARGIN + cellCounter * gridWidth * 0.5,
      posY,
      headerOptions
    );
    cellCounter++;
  }
  drawLine(
    page,
    { x: MARGIN + cellCounter * gridWidth * 2, y: posY - 7 },
    { x: MARGIN + cellCounter * gridWidth * 2, y: posY + ROW_HEIGHT - 16 },
    { thickness: 0.5, color: rgb(0.3, 0.33, 0.34) }
  );
  drawText(
    page,
    'OKRES',
    MARGIN + CELL_MARGIN + cellCounter * gridWidth * 2,
    posY,
    headerOptions
  );
  cellCounter++;
  drawLine(
    page,
    { x: MARGIN + cellCounter * gridWidth * 2, y: posY - 7 },
    { x: MARGIN + cellCounter * gridWidth * 2, y: posY + ROW_HEIGHT - 16 },
    { thickness: 0.5, color: rgb(0.3, 0.33, 0.34) }
  );
  drawText(
    page,
    type === 'engine' ? 'PAKIET' : 'LIMIT',
    MARGIN + CELL_MARGIN + cellCounter * gridWidth * 2,
    posY,
    headerOptions
  );
  cellCounter++;
  drawLine(
    page,
    { x: MARGIN + (cellCounter + 1) * gridWidth * 2.5, y: posY - 7 },
    {
      x: MARGIN + (cellCounter + 1) * gridWidth * 2.5,
      y: posY + ROW_HEIGHT - 16,
    },
    { thickness: 0.5, color: rgb(0.3, 0.33, 0.34) }
  );
  drawText(
    page,
    'CENA PAKIETU (BRUTO)',
    MARGIN + CELL_MARGIN + (cellCounter + 1) * gridWidth * 2.5,
    posY,
    headerOptions
  );
  cellCounter++;
  if (item.installment && item.installment !== '__INSTALLMENT__') {
    drawLine(
      page,
      { x: MARGIN + CELL_SHIFT + cellCounter * gridWidth * 3.25, y: posY - 7 },
      {
        x: MARGIN + CELL_SHIFT + cellCounter * gridWidth * 3.25,
        y: posY + ROW_HEIGHT - 16,
      },
      { thickness: 0.5, color: rgb(0.3, 0.33, 0.34) }
    );
    drawText(
      page,
      'RATA (BRUTTO)',
      MARGIN + CELL_MARGIN + CELL_SHIFT + cellCounter * gridWidth * 3.25,
      posY,
      headerOptions
    );
  }
};

const drawTableRow = (
  page: Object,
  item: Object,
  posY: number,
  type: string
) => {
  const cellOptions = { size: 11 };
  const rowWidth = page.getWidth() - 2 * MARGIN;
  let gridWidth = rowWidth / 12;
  let cellCount = 4;

  if (
    item.installment &&
    item.installment !== '__INSTALLMENT__' &&
    item.symbol
  ) {
    cellCount = 6;
  } else if (
    (item.installment && item.installment !== '__INSTALLMENT__') ||
    item.symbol
  ) {
    cellCount = 5;
  }
  let cellCounter = 0;
  if (item.symbol) {
    gridWidth++;
    drawHeaderText(
      page,
      item.symbol,
      MARGIN + CELL_MARGIN + CELL_SHIFT + cellCounter * gridWidth * 0,
      posY,
      cellOptions
    );
    cellCounter++;
    cellCount++;
  }
  drawHeaderText(
    page,
    `${item.age} mies.`,
    MARGIN + CELL_MARGIN + CELL_SHIFT + cellCounter * gridWidth * 0.5,
    posY,
    cellOptions
  );
  cellCounter++;
  drawHeaderText(
    page,
    type === 'engine'
      ? `${item.type[7].toUpperCase()}${item.type.substring(
          8,
          item.type.length
        )}`
      : `${item.distance} tys. km`,
    MARGIN + 3 * CELL_MARGIN + cellCounter * gridWidth * 2,
    posY,
    cellOptions
  );
  cellCounter++;
  drawHeaderText(
    page,
    isNaN(item.price) ? item.price : `${formatPrice(item.price)} zł`,
    MARGIN + 3 * CELL_MARGIN + (cellCounter + 1) * gridWidth * 2.5,
    posY,
    cellOptions
  );
  cellCounter++;
  if (item.installment && item.installment !== '__INSTALLMENT__') {
    drawHeaderText(
      page,
      isNaN(item.installment)
        ? item.installment
        : `${formatPrice(item.installment)} zł`,
      MARGIN + 3 * CELL_MARGIN + CELL_SHIFT + cellCounter * gridWidth * 3.25,
      posY,
      cellOptions
    );
  }
  drawLine(
    page,
    { x: MARGIN, y: posY + 5 },
    { x: page.getWidth() - MARGIN, y: posY + 5 },
    { thickness: 0.5, color: rgb(0.85, 0.85, 0.85) }
  );
  let selectedItem: any;
  if (item.selected) {
    selectedItem = item;
    drawLine(
      page,
      { x: MARGIN, y: posY + 3 },
      { x: page.getWidth() - MARGIN, y: posY + 3 },
      { thickness: 0.5, color: rgb(0, 0.27, 0.4) }
    );
    drawLine(
      page,
      { x: MARGIN, y: posY + 3 },
      { x: MARGIN, y: posY + 7 - ROW_HEIGHT },
      { thickness: 0.5, color: rgb(0, 0.27, 0.4) }
    );
    drawLine(
      page,
      { x: MARGIN, y: posY + 7 - ROW_HEIGHT },
      { x: page.getWidth() - MARGIN, y: posY + 7 - ROW_HEIGHT },
      { thickness: 0.5, color: rgb(0, 0.27, 0.4) }
    );
    drawLine(
      page,
      { x: page.getWidth() - MARGIN, y: posY + 7 - ROW_HEIGHT },
      { x: page.getWidth() - MARGIN, y: posY + 3 },
      { thickness: 0.5, color: rgb(0, 0.27, 0.4) }
    );
  }
  return selectedItem;
};

// eslint-disable-next-line space-before-function-paren
const printOffer = async (
  brand: string,
  product: Object,
  plans: Object | null,
  model: Object,
  option: Object | null,
  typeName: string,
  engine: string | null
) => {
  const pdfDoc = await createPDF();
  await loadFont(pdfDoc, '/fonts/fonts/helvetica.ttf', 'normal');
  await loadFont(pdfDoc, '/fonts/fonts/helvetica-bold.ttf', 'bold');
  let page = pdfDoc.addPage(PageSizes.A4);

  drawTextWithBackground(
    page,
    `${brand === 'vwd' ? 'VW' : brand} ${model.name}`.toUpperCase(),
    450,
    0,
    {
      type: 'bold',
      size: 22,
      color: rgb(1, 1, 1),
      background: rgb(0, 0.27, 0.4),
      centerX: true,
    }
  );

  let posY = 210;

  await addImage(pdfDoc, page, '/assets/vwfs-logo.png', {
    x: 50,
    y: 0,
    height: 60,
    maxWidth: 100,
  });

  await addImage(pdfDoc, page, `/assets/${model.image}`, {
    y: 40,
    width: 535,
    maxHeight: 150,
  });

  drawHeaderText(page, typeName, null, posY, {
    size: 7,
    type: 'normal',
    color: rgb(0.3, 0.33, 0.34),
  });
  posY += 20;

  const headerOptions = { size: 15 };
  let selectedItem: any;
  for (let i = 0; i < plans.length; i++) {
    if (posY + plans[i].options.length * ROW_HEIGHT > 700) {
      page = pdfDoc.addPage(PageSizes.A4);
      posY = 60;
    }
    drawHeaderText(page, `${plans[i].name}:`, null, posY, headerOptions);
    posY += 44;
    if (engine) {
      posY -= 20;
      drawHeaderText(
        page,
        `Pojemność silnika: ${engine}`,
        null,
        posY,
        headerOptions
      );
      posY += 44;
    }
    drawTableHeader(page, plans[i].options[0], posY, plans[i].type);
    posY += ROW_HEIGHT;
    for (let j = 0; j < plans[i].options.length; j++) {
      const item = plans[i].options[j];
      const result = drawTableRow(page, item, posY, plans[i].type);
      if (result) {
        selectedItem = result;
      }
      posY += ROW_HEIGHT;
    }
    posY += 22;
  }
  plans.forEach((item: Object) => {});

  if (
    (posY > 550 && product.scopes.rows.length === 3) ||
    (posY > 650 && product.scopes.rows.length === 2) ||
    (posY > 450 && product.scopes.rows.length === 4) ||
    (posY > 350 && product.scopes.rows.length === 5)
  ) {
    page = pdfDoc.addPage(PageSizes.A4);
    posY = 100;
  } else {
    posY += 20;
  }

  drawHeaderText(page, 'Zakresy:', null, posY, headerOptions);
  posY += 50;
  drawScopesHeaders(page, product.scopes.headers, posY);
  posY += 10;
  const { rows } = product.scopes;
  const scopeWidth =
    product.scopes.headers[product.scopes.headers.length - 1].length < 4
      ? SCOPE_WIDTH_SHORT
      : SCOPE_WIDTH;
  for (let idx = 0; idx < rows.length; idx++) {
    const item = rows[idx];
    console.log({
      scope: item,
      product,
      selectedItem,
      res: selectedItem?.type === item.subtype,
    });
    const descWidth: number =
      page.getWidth() -
      MARGIN -
      2 * CELL_MARGIN -
      item.entries.length * (scopeWidth + 2 * CELL_MARGIN);
    posY += 10;
    const lines = drawWrappedText(
      page,
      item.entries[0].caption
        ? `${item.entries[0].caption}${item.entries[0].value}`
        : item.entries[0].value,
      MARGIN + CELL_MARGIN,
      posY,
      descWidth,
      'down',
      {
        size: 6,
        type:
          selectedItem && selectedItem?.type === item.subtype
            ? 'bold'
            : 'normal',
        color:
          selectedItem && selectedItem?.type === item.subtype
            ? rgb(0, 0.27, 0.4)
            : rgb(0.3, 0.33, 0.34),
      }
    );

    for (let j = 1; j < item.entries.length; j++) {
      const positionY = posY + (lines.length / 2) * 8 - 12;
      await addImage(
        pdfDoc,
        page,
        item.entries[j].value
          ? '/assets/checkbox-checked.png'
          : '/assets/checkbox.png',
        {
          x:
            page.getWidth() -
            MARGIN -
            (item.entries.length - j - 0.5) * scopeWidth -
            (item.entries.length - j) * CELL_MARGIN -
            14,
          y: positionY,
          height: 12,
          maxWidth: 12,
        }
      );
    }

    posY += lines.length * 8;
    if (idx !== product.scopes.rows.length - 1) {
      drawLine(
        page,
        { x: MARGIN, y: posY },
        { x: page.getWidth() - MARGIN, y: posY },
        { thickness: 0.5, color: rgb(0.85, 0.85, 0.85) }
      );
    }
  }
  posY += 25;
  drawText(
    page,
    'Wydruk nie stanowi oferty w rozumieniu kodeksu cywilnego, a szczegółowe warunki świadczenia usług serwisowych określa umowa.',
    MARGIN,
    posY,
    { size: 5.5 }
  );

  posY += 10;
  let currentDate = new Date();
  drawText(
    page,
    `Data wydruku: ${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()} `,
    MARGIN,
    posY,
    { size: 5.5 }
  );
  // product.scopes.rows.map(async (item: Object, idx: nuber) => {

  // })

  //Save PDF
  const pdfBytes = await pdfDoc.save();
  downloadAsPDF('offer', pdfBytes);
};

export { printOffer };
