import type { JSXElementConstructor, ReactNode } from 'react';
import React, { Fragment } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { connect } from 'react-redux';
import type { Action, Dispatch } from 'redux';
import { bindActionCreators } from 'redux';

import Routes from './routes/appRoutes';

import Footer from './components/layout/Footer';
import Header from './components/layout/Header';
import PageContainer from './components/layout/PageContainer';

import { logout, reAuth } from './redux/actions/AuthActions';
import { getConfiguration } from './redux/actions/ConfigActions';
import { setCurrentBrand, getBrands } from './redux/actions/BrandsActions';
import {
  selectCurrentBrandInfo,
  selectHasData,
} from './redux/selectors/configurationSelectors';
import { selectOfferModel } from './redux/selectors/offerSelectors';

import LoginView from './views/LoginView';
import NotSupportedView from './views/NotSupportedView';

type ApplicationProps = {
  currentBrandInfo: Object;
  isLoggedIn: boolean;
  model: Object | null;
  logout: Function;
  reAuth: Function;
  getBrands: Function;
  getConfiguration: Function;
  setCurrentBrand: Function;
  userData: Object | null;
  user: Object;
  isLogging: boolean;
};

class Application extends React.Component<ApplicationProps> {
  componentDidMount() {
    const { getBrands } = this.props;

    getBrands();
  }

  isSupported(): boolean {
    const agent = window.navigator.userAgent;

    if (
      agent.indexOf('Safari') > -1 ||
      agent.indexOf('OP') > -1 ||
      agent.indexOf('Firefox') > -1
    ) {
      return true;
    }
    let IExplorerAgent =
      agent.indexOf('MSIE') > -1 || agent.indexOf('rv:') > -1;
    return !IExplorerAgent;
  }

  renderRoutes(): Array<ReactNode> {
    const { currentBrandInfo, logout, model, setCurrentBrand, userData } =
      this.props;
    return Routes.main.map(
      (item: Object, idx: number): ReactNode => (
        <Route path={item.path} key={idx} exact>
          {(route: Object): ReactNode => {
            // window.scrollTo(0, 0)

            return (
              <Fragment>
                {item.header && (
                  <Header
                    {...route}
                    logout={logout}
                    brandInfo={currentBrandInfo}
                    model={model}
                    user={userData.displayName}
                  />
                )}
                {item.header ? (
                  <PageContainer wide={item.isWide}>
                    <item.component {...route} />
                  </PageContainer>
                ) : (
                  <item.component {...route} />
                )}
                {item.footer && <Footer setCurrentBrand={setCurrentBrand} />}
              </Fragment>
            );
          }}
        </Route>
      )
    );
  }

  renderUnsupported(): ReactNode {
    return <NotSupportedView />;
  }

  renderLogin(): ReactNode {
    return <LoginView />;
  }

  renderContent(): ReactNode {
    const { isLoggedIn } = this.props;
    if (!this.isSupported()) {
      return this.renderUnsupported();
      // } else if (!isLoggedIn && !user && !isLogging) {
    } else if (!isLoggedIn) {
      return (
        <Fragment>
          {this.renderLogin()}
          <Route render={(): ReactNode => <Redirect to='/login' />} />
        </Fragment>
      );
    }
    if (isLoggedIn) {
      return (
        <BrowserRouter>
          <Switch>
            {this.renderRoutes()}
            <Route render={(): ReactNode => <Redirect to='/configuration' />} />
          </Switch>
        </BrowserRouter>
      );
    }
  }

  render(): ReactNode {
    return (
      <BrowserRouter>
        <Switch>{this.renderContent()}</Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state: Object): Object => {
  return {
    currentBrandInfo: selectCurrentBrandInfo(state),
    model: selectOfferModel(state),
    isLoggedIn: state.auth.isLoggedIn,
    isLogging: state.auth.isLogging,
    userData: state.auth.data,
    hasData: selectHasData(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>): Object => {
  const actions = {
    getConfiguration,
    reAuth,
    logout,
    setCurrentBrand,
    getBrands,
  };
  return bindActionCreators(actions, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Application as unknown as JSXElementConstructor<never>);
