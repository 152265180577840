import BrandsApi from '../../api/BrandsApi';
import { BrandsAction } from '../constants/BrandsAction';

type ClearBrandsActionType = {
  type: typeof BrandsAction.CLEAR;
};

type GetBrandsActionType = {
  type: typeof BrandsAction.GET;
};

type GetBrandsSuccessActionType = {
  type: typeof BrandsAction.GET_SUCCESS;
  data: Object;
};

type GetBrandsFailedActionType = {
  type: typeof BrandsAction.GET_FAILED;
};

type SetBrandsActionType = {
  type: typeof BrandsAction.SET;
  brands: Object;
};

type SetCurrentBrandActionType = {
  type: typeof BrandsAction.SET_CURRENT;
  name: string;
};

export type BrandsActionType =
  | ClearBrandsActionType
  | GetBrandsActionType
  | GetBrandsSuccessActionType
  | GetBrandsFailedActionType
  | SetBrandsActionType
  | SetCurrentBrandActionType;

const clearBrands = (): BrandsActionType => ({
  type: BrandsAction.CLEAR,
});

const getBrandsBegin = (): BrandsActionType => ({
  type: BrandsAction.GET,
});

const getBrandsSuccess = (): BrandsActionType => ({
  type: BrandsAction.GET_SUCCESS,
});

const getBrandsFailed = (): BrandsActionType => ({
  type: BrandsAction.GET_FAILED,
});

const getBrands = (): Function => {
  return (dispatch: Function) => {

    dispatch(getBrandsBegin());
    BrandsApi.get()
      .then((data: Object) => {
        // const { brands, carProtectProducts, ...rest } = data.config;
        dispatch(getBrandsSuccess());
        dispatch(setBrands(data))
      })
      .catch(() => {
        dispatch(getBrandsFailed());
      })
  }
}


const setBrands = (brands: Object): BrandsActionType => ({
  type: BrandsAction.SET,
  brands,
});

const setCurrentBrand = (name: string): BrandsActionType => ({
  type: BrandsAction.SET_CURRENT,
  name,
});

export { clearBrands, getBrands, setBrands, setCurrentBrand };
